// src/App.js
import React from 'react';
import Header from './app/Header';
import MainContent from './MainContent';
import Footer from './app/Footer';
import EducationSection from './app/EducationSection';

function App() {
  return (
    <div className="App">
    <Header/>
    <MainContent/>
    <Footer/>
    </div>
  );
}

export default App;
